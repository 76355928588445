import React from "react";
import "./contactUs.scss";

//Images
import footer from "../../images/contact-us.svg";

function ContactUs() {
	return (
		<div className="contact-us" id="contact-us">
			<img src={footer} alt="" />
			<h2>Contact Us</h2>

			<button className="button-dark">
				<a
					href="https://calendly.com/karl-storilabs/consultation"
					target="_blank"
					rel="noopener noreferrer"
				>
					Book a call
				</a>
			</button>
			<p>
				Illustrations by Streamline, used under{" "}
				<a
					href="https://creativecommons.org/licenses/by/4.0/"
					target="_blank"
					rel="noopener noreferrer"
				>
					CC BY 4.0.{" "}
				</a>
				Source:
				<a
					href="https://www.figma.com/community/file/1105485675187256849"
					target="_blank"
					rel="noopener noreferrer"
				>
					{" "}
					Link
				</a>
			</p>
		</div>
	);
}

export default ContactUs;
